import React from "react";
import css from "./Footer.module.css";
import Logo from "../../assets/logo.png";
import {InboxIcon, PhoneIcon, LoginIcon, UsersIcon, LinkIcon, LocationMarkerIcon} from "@heroicons/react/outline";

const Footer = () => {
  return (
    <div className={css.fWraper}>
        <hr/>
        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt="" />
                <span>amazon</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Conact Us</span>
                    <span className={css.line}>
                        <LocationMarkerIcon className={css.icon} />
                        <span>111 North Avenue Orlando, FL 32801</span>
                    </span>
                    <span className={css.line}>                   
                        <PhoneIcon className={css.icon} />
                        <a href="Tel: 352-306-4415">352-306-4415</a>
                    </span>
                    <span className={css.line}>
                        <InboxIcon className={css.icon} />
                        <a href="mailto: support@amazon.com">support@amazon.com</a>
                    </span>
                </div>
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account</span>
                    <span className={css.line}>
                        <LoginIcon className={css.icon} />
                        Log-in / Sign-Up
                    </span>
                </div>    
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.line}>
                        <UsersIcon className={css.icon} />
                        <a href="/about">
                            <p>About Us</p>
                        </a>
                    </span>
                </div>    
            </div>

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.line}>
                        <LinkIcon className={css.icon} />
                        <a href="/about">
                            <p>Safety and Privacy Terms</p>
                        </a>
                    </span>
                </div>    
            </div>            
        </div>

        <div className={css.copyright}>
            <span>  Copyright ©2022 by Amazon, Inc.</span>
            <span>All Rights Reserved.</span>
        </div>
    </div>
  )
}

export default Footer;