import React from "react";
import css from "./Hero.module.css";
import HeroImg from "../../assets/hero.png";
import {RiShoppingBagFill} from "react-icons/ri";
import {FaArrowRight} from "react-icons/fa";
import {motion} from "framer-motion";

const Hero = () => {

  const transition = {duration: 3, type: "spring"}
  return (

    <div className={css.container}>

        <div className={css.left}>
            <span className={css.txt1}>Skin Protection Cream</span>
            <div className={css.txt2}>
                <span>Trendy Collection</span>
                <span>
                Experience the power of modern skincare innovations designed to shield and nourish your skin, making it the perfect companion for your daily beauty routine.
                </span>
            </div>
        </div>

        <div className={css.wrapper}>
            <motion.div 
            initial={{bottom: "4rem"}}
            whileInView={{bottom: "0rem"}}
            transition={transition}
            className={css.blueCircle}></motion.div>

            <motion.img 
            initial={{bottom: "-4rem"}}
            whileInView={{bottom: "0rem"}}
            transition={transition}
            src={HeroImg} alt="" width={600}/>

            <motion.div
             initial={{right: "0%"}}
            whileInView={{right: "5%"}}
            transition={transition}
            className={css.cart2}>
                <RiShoppingBagFill/>
             <div className={css.signUp}>
                <span>
                    Best Sign Up Offer!
                </span>
                <div>
                    <FaArrowRight/>
                </div>
             </div>   
            </motion.div>
            
        </div>

        <div className={css.right}>
            <div className= {css.traffic}>
                <span>1.5M+</span>
                <span>Monthly Traffic</span>
            </div>

            <div className={css.customers}>
                <span>100K+</span>
                <span>Satisfied Customers</span>
            </div>
        </div>
    </div>
  )
}

export default Hero;