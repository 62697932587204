import profile1 from "../assets/profile1.png";
import profile2 from "../assets/profile2.png";
import profile3 from "../assets/profile3.png";
import profile4 from "../assets/profile4.png";
import profile5 from "../assets/profile5.png";
import profile6 from "../assets/profile6.png";


export const TestimonialsData = [
    {
        image : profile1,
        comment: "I've been using this skincare product for a few weeks now, and I can't believe the results! My skin feels incredibly soft and looks noticeably brighter. I love it!",
        name: "Natasha"
    },
    {
        image : profile2,
        comment: "I've struggled with acne for years, but this skincare product has been a game-changer for me. It's cleared up my skin and reduced redness. I can finally go makeup-free with confidence.",
        name: "John Alendon"
    },
    {
        image : profile3,
        comment: "This skincare product is a lifesaver during the winter months. It's so hydrating and has helped me combat dry, flaky skin. I can't imagine my skincare routine without it.",
        name: "Marrione"
    },
    {
        image : profile4,
        comment: "I'm in my late 40s and have tried numerous anti-aging products, but this one is by far the best. It's reduced the appearance of fine lines and wrinkles, and my skin looks more youthful than ever.",
        name: "Joelen Hera"
    },
    {
        image : profile5,
        comment: "I have sensitive skin, so finding the right skincare product is a challenge. This one is gentle yet effective. It hasn't caused any irritation, and my skin feels calmer and more balanced.",
        name: "Gal Gadot"
    },
    {
        image : profile6,
        comment:"I'm a skincare enthusiast, and I'm always on the lookout for innovative products. This one has exceeded my expectations.I am very satisfied with their products.",
        name: "Xenia Nathalie"
    }
]